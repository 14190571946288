import { AppShell, Group, Stack } from "@mantine/core";
import { useRouter } from "next/router";
import { AdminLinks } from "./AdminLinks";
import { MainLinks } from "./MainLinks";
const { Navbar, Footer } = AppShell;

const NavbarComp = () => {
  // const open = useAppStore((state) => state.open);
  // const user = useAuthStore((state) => state.user);
  const router = useRouter();
  // const logout = useAuthStore((state) => state.logout);
  // const { t } = useTranslation(["navbar", "common"]);
  const showBoardLinks = router.asPath.indexOf("/boards") === 0;
  const showAdminLinks = router.asPath.indexOf("/admin") === 0;
  const showBar = showBoardLinks || showAdminLinks;

  // let { boardShortLink, boardSlug } = router.query;
  // console.log("boardShortLink :", boardShortLink);
  // const { data } = useBoard(boardShortLink);
  return (
    <>
      {showBar && (
        <Navbar
          visibleFrom="xs"
          // width={{ base: 70, md: 160 }}
          // p="xs"
        >
          <Stack justify="center" gap={15} pl={0} m="sm">
            {showBoardLinks && <MainLinks />}
            {showAdminLinks && <AdminLinks />}
          </Stack>
        </Navbar>
      )}
      {showBar && (
        <Footer
          hiddenFrom="xs"
          // width={{ base: 70, md: 160 }}
          p="xs"
          pb="xl"
        >
          <Group justify="center" gap={5} pl={0}>
            {showBoardLinks && <MainLinks />}
            {showAdminLinks && <AdminLinks />}
          </Group>
        </Footer>
      )}
    </>
  );
};
export default NavbarComp;
