import {
  ActionIcon,
  Box,
  Center,
  Group,
  Loader,
  SimpleGrid,
  SimpleGridProps,
  Stack,
  TextInput,
  Title,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconArrowDown, IconCircleX, IconSearch } from "@tabler/icons-react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";
import { ReactNode, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ListResult, PAGE_SIZE } from "../../common/api";
import { Record } from "../../common/record.schema";
import Loading from "./Loading";
// eslint-disable-next-line unused-imports/no-unused-vars
export type RenderFunction = (record: Record) => React.ReactNode;
export type RenderListFunction = (
  // eslint-disable-next-line unused-imports/no-unused-vars
  records: Record[],
  // eslint-disable-next-line unused-imports/no-unused-vars
  isLoading: boolean,
  // eslint-disable-next-line unused-imports/no-unused-vars
  renderFunction: RenderFunction
) => React.ReactNode;
interface Props {
  // eslint-disable-next-line unused-imports/no-unused-vars
  listFunction: (offset: number, search: string) => Promise<any>;
  renderFunction: RenderFunction;
  queryParams?: any[];
  emptyComponent?: ReactNode;
  header?: ReactNode;
  queryKey?: string[];
  getListComponent?: RenderListFunction;
  searchable?: boolean;
  cols?: number;
  initialData?: ListResult;
  className?: string;
}
const InfiniteList = ({
  listFunction,
  renderFunction,
  initialData,
  queryParams,
  emptyComponent,
  header,
  getListComponent,
  queryKey,
  searchable,
  spacing,
  cols,
  className,
}: Props & SimpleGridProps) => {
  // console.log("records :", records);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebouncedValue(search, 200);
  queryParams = queryParams || [];
  queryKey = queryKey || ["records"];
  queryKey = [...queryKey, debouncedSearch, ...queryParams];
  const { data, fetchNextPage, hasNextPage, refetch, isLoading } =
    useInfiniteQuery(
      queryKey,
      ({ pageParam = 0 }) => {
        // console.log("run query");
        // console.log("pageParam :", pageParam);
        return listFunction(pageParam, debouncedSearch);
      },
      {
        initialData: initialData
          ? { pageParams: [], pages: [initialData] }
          : undefined,
        getNextPageParam: (lastPage, pages) => {
          const count = pages[0].count;
          // console.log("pages :", pages);
          // console.log("lastPage :", lastPage);
          const nextPageStart = lastPage.offset + PAGE_SIZE;
          // console.log("count :", count);
          // console.log("nextPageStart :", nextPageStart);
          const hasNext = nextPageStart < count;
          // console.log("hasNext :", hasNext);
          if (hasNext) {
            const nextPage = lastPage.offset + PAGE_SIZE;
            // console.log("nextPage :", nextPage);
            return nextPage;
          }
        },
        // initialData: initRes,
      }
    );
  const getEmptyComponent = () => {
    if (search !== "")
      return (
        <Stack>
          <Title ta="center" order={3}>
            {t("Nothing matching your search")}
          </Title>
        </Stack>
      );
    return (
      emptyComponent || (
        <Stack>
          <Title ta="center" order={3}>
            {t("Nothing to see here")}
          </Title>
        </Stack>
      )
    );
  };
  const getHeadComponent = () =>
    header || (
      <>
        {searchable && (
          <Group justify="right" mb={15}>
            <TextInput
              // label={t("search")}
              className="search"
              leftSection={<IconSearch />}
              value={search}
              placeholder={t("search")}
              flex={1}
              onChange={(event) => setSearch(event.currentTarget.value)}
            />
            <ActionIcon
              onClick={() => setSearch("")}
              disabled={search.length === 0}
            >
              <IconCircleX />
            </ActionIcon>
          </Group>
        )}
      </>
    );
  const getDefaultListComponent = (
    records: Record[],
    isLoading: boolean,
    renderFunction: RenderFunction
  ) => {
    if (getListComponent)
      return getListComponent(records, isLoading, renderFunction);
    const defaultCols = { base: 1, xs: 1, sm: 2, md: 3 };
    const defaultSpacing = { base: "sm", xs: "sm", sm: "md" };
    // [
    //   { maxWidth: 980, cols: 3, spacing: "md" },
    //   { maxWidth: 755, cols: 2, spacing: "sm" },
    //   { maxWidth: 600, cols: 1, spacing: "sm" },
    // ];
    // if (cols)
    //   defaultBreakpoints = defaultBreakpoints.filter((b) => b.cols <= cols);
    // console.log("defaultBreakpoints :", defaultBreakpoints);
    return (
      <>
        {records.length === 0 && isLoading && <Loading />}
        <SimpleGrid
          cols={cols || defaultCols}
          spacing={spacing || defaultSpacing}
        >
          {records && records.map(renderFunction)}
        </SimpleGrid>
      </>
    );
  };
  // console.log("isLoading :", isLoading);
  // console.log("hasNextPage :", hasNextPage);
  // console.log("hasNextPage :", hasNextPage);
  const { t } = useTranslation();
  let records: Record[] = [];
  // let count = 0;
  if (data?.pages.length) {
    records = [];
    // console.log("pages :", data.pages);
    // count = data.pages[0].count;
    // console.log("count :", count);
    data.pages.map((page) => {
      records = records.concat(page.records);
    });
  }
  const empty = !hasNextPage && !isLoading && records.length === 0;
  // console.log("empty :", empty);
  // records.map((b) => console.log("board :", b._id));
  // console.log("records :", records);
  // console.log("count :", count);
  return (
    <Stack className={className}>
      <InfiniteScroll
        dataLength={records.length} //This is important field to render the next data
        next={fetchNextPage}
        hasMore={hasNextPage === true}
        loader={
          <Center h={70}>
            <Loader />
          </Center>
        }
        endMessage={<>{empty && <Box mt={20}>{getEmptyComponent()}</Box>}</>}
        // below props only if you need pull down functionality
        refreshFunction={refetch}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <Center h={50}>{!!(records.length > 0) && <IconArrowDown />}</Center>
        }
        releaseToRefreshContent={
          <Center h={50}>
            <Loader size="sm" />
          </Center>
        }
      >
        {getHeadComponent()}
        {getDefaultListComponent(records, isLoading, renderFunction)}
      </InfiniteScroll>
    </Stack>
  );
};
export default InfiniteList;
