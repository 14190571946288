import { ActionIcon, ActionIconProps, Drawer, Indicator } from "@mantine/core";
import { IconAward, IconBell } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  listAchievementDefinitions,
  listNotifications,
  readAll,
} from "../../common/api";
import {
  Notification,
  NotificationType,
} from "../../common/notifications/notification.schema";
import { useAuthStore } from "../../common/stores/auth.store";
import NotificatonList from "./NotificationList";
import { showNotification } from "@mantine/notifications";

const NotificationsButton = (props: ActionIconProps) => {
  const [opened, setOpened] = useState(false);
  const user = useAuthStore((s) => s.user);
  const [lastUnread, setLastUnread] = useState<Notification>();
  const { mutate: doReadAll } = useMutation(readAll);
  const { data: achievementDefinitions } = useQuery(
    ["achievement-definitions"],
    listAchievementDefinitions
  );
  const onClickBell = () => {
    setOpened(true);
    if (lastUnread) {
      doReadAll();
    }
  };

  const { data } = useQuery(
    ["unread-notifications"],
    () => {
      return listNotifications({
        limit: 1,
        params: { user: user?._id, read: { $ne: true } },
      });
    },
    {
      refetchInterval: 30000,
      onSuccess(data) {
        // console.log("data :", data);
        const prevLastUnread = lastUnread;
        const newUnread = data?.count > 0 && data.records[0];
        if (
          newUnread &&
          (!prevLastUnread || prevLastUnread?.createdAt < newUnread.createdAt)
        ) {
          setLastUnread(newUnread);
          if (
            lastUnread &&
            newUnread.type === NotificationType.ACHIEVEMENT_COMPLETE
          ) {
            const achievementDefinition = achievementDefinitions?.records?.find(
              (a) => a.id === newUnread.achievement
            );
            if (achievementDefinition) {
              showNotification({
                title: "Achievement Complete",
                message: achievementDefinition.name,
                color: "green",
                icon: <IconAward size={20} />,
              });
            }
          }
        }
      },
    }
  );
  const hasUnread = data && data?.count > 0;
  // console.log("data :", data);
  return (
    <Indicator position="bottom-end" disabled={!hasUnread}>
      <ActionIcon {...props} onClick={() => onClickBell()} variant="light">
        <IconBell />
      </ActionIcon>
      <Drawer
        styles={{ content: { overflowY: "scroll" } }}
        opened={opened}
        onClose={() => setOpened(false)}
        position="right"
      >
        <NotificatonList onClick={() => setOpened(false)} />
      </Drawer>
    </Indicator>
  );
};
export default NotificationsButton;
