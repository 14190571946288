import getSymbolFromCurrency from "currency-symbol-map";
import slug from "slug";
import { Board } from "./boards/board.schema";
import { Event, MIN_MEMBERS, Registration } from "./events/event.schema";
import { Member } from "./members/member.schema";
import { Organisation } from "./organisations/organisation.schema";
import { Record } from "./record.schema";
import sports, { Sport, Weapon } from "./sports";
import { PlanType, User } from "./users/user.schema";
export const MAX_NAME_STRING = 36;
export const USFA_NUMBER_LENGTH = 9;
export enum Currency {
  USD = "USD",
  AUD = "AUD",
  EUR = "EUR",
  GBP = "GBP",
  CAD = "CAD",
}
export const PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=net.scoreboarder.twa&referrer=utm_source=site&utm_medium=link&utm_campaign=app";
export function getInitials(name: string) {
  // name = name.replace(/[^a-zA-Z0-9]/g, "");
  name = name.replace(/[^\p{L}\p{N}]/gu, "");
  if (!name) return "";
  const words = name.split(" ");
  let initials = words.map((word) => word[0]).join("");
  if (initials.length > 2) {
    initials = initials.substring(0, 2);
  }
  return initials.toUpperCase();
}
function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return Math.abs(hash);
}
// const colors = [
//     'red',
export function getColor(str: string): string {
  // console.log("str :", str);
  // MANTINE_COLORS
  //   console.log("MANTINE_COLORS :", MANTINE_COLORS);
  //   return "red";
  const hash = hashCode(str);
  // console.log("hash :", hash);
  // console.log("MANTINE_COLORS :", MANTINE_COLORS);
  // console.log("hash % MANTINE_COLORS.length :", hash % MANTINE_COLORS.length);
  // const colors = DEFAULT_THEME.colors.filter((color) => color !== "dark");
  const colors = [
    "red",
    "pink",
    "grape",
    "violet",
    "indigo",
    "blue",
    "cyan",
    "teal",
    "green",
    "lime",
    "yellow",
    "orange",
  ];
  const color = colors[hash % colors.length];
  // if (color === "dark") return getColor(str + "x");
  return color;
}

export function getSlug(record: Record) {
  const name: string = (record as any)?.name;
  const lastName: string | undefined = (record as any)?.lastName; // for user and member
  if (!name) return "record";
  if (lastName && lastName.length > 0) return slug(`${name} ${lastName}`);
  return slug(name);
}
export function getUserLink(user: User) {
  if (!user) return "/";
  return `/admin/users/${user._id}`;
}
export function getOrganisationLink(organisation: Organisation) {
  if (!organisation) return "/";
  return `/organisations/${organisation._id}`;
}
export function getBoardLink(board: Board) {
  if (!board) return "/";
  // console.log("member :", member);
  // console.log("board :", board);
  return `/boards/${board.shortLink}/${getSlug(board)}`;
}
export function getMemberLink(board: Board, member: Member) {
  if (!board || !member) return "/";
  // console.log("member :", member);
  // console.log("board :", board);
  return `${getBoardLink(board)}/members/${member.shortLink}/${getSlug(
    member
  )}`;
}
export function getEventLink(board: Board, event: Event) {
  if (!board || !event) return "/";
  // console.log("member :", member);
  // console.log("board :", board);
  return `${getBoardLink(board)}/events/${event.shortLink}/${getSlug(event)}`;
}
const PLANS = [
  {
    type: PlanType.FREE,
    name: "Free",
    leaderboardSize: 20,
    eventSize: 8,
    teamLimit: 2,
    teamEventSize: 4,
    matchHistory: 60 * 60 * 24 * 365, //year
    price: 0,
  },
  {
    type: PlanType.TEAM,
    name: "Team",
    leaderboardSize: 100,
    eventSize: 64,
    teamLimit: 16,
    teamEventSize: 8,
    matchHistory: 60 * 60 * 24 * 365 * 4, //4 years
    price: 7,
  },
  {
    type: PlanType.CLUB,
    name: "Club",
    leaderboardSize: -1,
    eventSize: -1,
    teamLimit: -1,
    teamEventSize: -1,
    matchHistory: -1,
    price: 14,
  },
  {
    type: PlanType.ORG,
    name: "Organisation",
    leaderboardSize: -1,
    eventSize: -1,
    teamLimit: -1,
    teamEventSize: -1,
    matchHistory: -1,
    price: 0,
  },
];
export function getPlan(type: PlanType) {
  return PLANS.find((l) => l.type === type);
}
export function getBoardPlan(board: Board) {
  if (!board.plan) return PLANS[0];
  const plan = getPlan(board.plan);
  return plan || PLANS[0];
}
export function getBoardPayments(board: Board) {
  return false;
  return board?.paymentsEnabled;
}
export function getBoardCurrencySymbol(board: Board): string {
  const code = getBoardCurrencyCode(board);
  const symbol = getSymbolFromCurrency(code) || "$";
  return symbol;
}
export function getBoardCurrencyCode(board: Board): string {
  return (board.currency || "USD").toUpperCase();
}
export function getRecordId(record: any): string {
  if (!record) throw new Error("can't get record id from null");
  if (record._id) return record._id.toString();
  return record.toString();
}
export function getSiteUrl(): string {
  return process.env.NEXT_PUBLIC_SITE_URL + "";
}
export const isProd = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";
export function arrayMove(arr: any[], fromIndex: number, toIndex: number) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}
export function getUniqueClubs(records: Member[] | Registration[]) {
  const clubs = records.map((r) => r.club).filter((club) => club);
  return [...new Set(clubs)];
}
export function getUniqueCountries(records: Member[] | Registration[]) {
  const countries = records.map((r) => r.country).filter((country) => country);
  // console.log("countries :", countries);
  return [...new Set(countries)];
}

export function getShowClubs(records: Member[] | Registration[]) {
  return getUniqueClubs(records).length > 1;
}
export function getShowCountries(records: Member[] | Registration[]) {
  return getUniqueCountries(records).length > 1;
}
export function getWeaponOptions(sport: Sport) {
  const weaponOptions = [
    { value: Weapon.EPEE, label: "Epee" },
    { value: Weapon.FOIL, label: "Foil" },
    { value: Weapon.SABRE, label: "Sabre" },
    { value: Weapon.MIXED, label: "Mixed" },
    { value: Weapon.HEMA_RAPIER, label: "Rapier (HEMA)" },
    { value: Weapon.HEMA_LONGSWORD, label: "Longsword (HEMA)" },
    { value: Weapon.HEMA_SABRE, label: "Sabre (HEMA)" },
    { value: Weapon.HEMA_RAPIER_DAGGER, label: "Rapier & dagger (HEMA)" },
    { value: Weapon.HEMA_SWORD_BUCKLER, label: "Sword & buckler (HEMA)" },
  ];
  const filteredWeapons = weaponOptions.filter((w) => {
    switch (w.value) {
      case Weapon.FOIL:
        return sport === Sport.FENCING;
      case Weapon.EPEE:
        return sport === Sport.FENCING;
      case Weapon.SABRE:
        return sport === Sport.FENCING;
      case Weapon.MIXED:
        return true;
      case Weapon.HEMA_RAPIER:
        return sport === Sport.HEMA;
      case Weapon.HEMA_LONGSWORD:
        return sport === Sport.HEMA;
      case Weapon.HEMA_SABRE:
        return sport === Sport.HEMA;
      case Weapon.HEMA_RAPIER_DAGGER:
        return sport === Sport.HEMA;
      case Weapon.HEMA_SWORD_BUCKLER:
        return sport === Sport.HEMA;
      default:
    }
  });
  return filteredWeapons;
}
export function getHasWeapons(sport?: Sport) {
  return sport === Sport.FENCING || sport === Sport.HEMA;
}

export function getHasDoubles(sport?: Sport) {
  return (
    sport === Sport.BADMINTON ||
    sport === Sport.TENNIS ||
    sport === Sport.PICKLEBALL ||
    sport === Sport.PADEL ||
    sport === Sport.SQUASH ||
    sport === Sport.TABLE_TENNIS
  );
}
export function getHasSets(sport?: Sport) {
  return (
    sport === Sport.BADMINTON ||
    sport === Sport.TENNIS ||
    sport === Sport.PICKLEBALL ||
    sport === Sport.PADEL ||
    sport === Sport.SQUASH ||
    sport === Sport.TABLE_TENNIS
  );
}
export function getSportConfig(sport?: Sport) {
  sport = sport || Sport.FENCING;
  const sportConfig = sports.find((s) => s.sport === sport);
  return sportConfig;
}
export function getMinTeamMembers(sport?: Sport) {
  const sportConfig = getSportConfig(sport);
  return sportConfig?.teamMinMembers || MIN_MEMBERS;
}
