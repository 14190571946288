import { Anchor, MantineSize, useComputedColorScheme } from "@mantine/core";
import Link from "next/link";
import { Board } from "../../common/boards/board.schema";
import { getBoardLink } from "../../common/util";

const BoardLink = ({ board, size }: { board: Board; size?: MantineSize }) => {
  const colorScheme = useComputedColorScheme();
  return (
    <Anchor
      component={Link}
      href={getBoardLink(board)}
      size={size}
      style={(theme) => ({
        color: colorScheme === "dark" ? "white" : "black",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: theme.fontSizes.md,
        "@media (maxWidth: 755px)": {
          fontSize: theme.fontSizes.sm,
        },
        "@media (maxWidth: 576px)": {
          fontSize: theme.fontSizes.xs,
        },
        "@media (maxWidth: 400px)": {
          maxWidth: 100,
        },
        "@media (maxWidth: 300px)": {
          maxWidth: 80,
        },
      })}
    >
      {board.name}
    </Anchor>
  );
};
export default BoardLink;
