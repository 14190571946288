import {
  Box,
  Group,
  Stack,
  Text,
  UnstyledButton,
  useComputedColorScheme,
} from "@mantine/core";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import classes from "./NavLinks.module.css";
interface LinkProps {
  icon: React.ReactNode;
  label: string;
  href: string;
  className?: string;
}
interface LinkItemProps extends LinkProps {
  base: string;
}
function LinkItem({ icon, label, href, base }: LinkItemProps) {
  // console.log("href :", href);
  const { t } = useTranslation();
  const computedColorScheme = useComputedColorScheme("light");
  label = t(label);
  const url = `${base}${href}`;
  // console.log("url :", url);
  const router = useRouter();
  let current = router.asPath.indexOf(url) === 0;
  if (href === "") current = router.asPath === url;
  // console.log("router.asPath :", router.asPath);
  // console.log("current :", current);
  let iconColor = computedColorScheme === "dark" ? "blue" : "gray";
  if (current) iconColor = computedColorScheme === "dark" ? "gray" : "blue";
  return (
    <Box>
      <UnstyledButton
        component={Link}
        href={url}
        className={classes.linkItemButton}
        p={0}
        aria-label={label}
      >
        <Stack align="center" gap={0} mx={4} hiddenFrom="md">
          <Text color={iconColor}>{icon}</Text>
          <Text size={"10px"}>{label}</Text>
        </Stack>
        <Group pt={4} pl={10} visibleFrom="md">
          <Text c={iconColor}>{icon}</Text>
          <Text pb={6} size="xs">
            {label}
          </Text>
        </Group>
      </UnstyledButton>
    </Box>
  );
}

function NavLinks({ base, data }: { base: string; data: LinkProps[] }) {
  const displayData = [...data];
  const links = displayData.map((link) => (
    <LinkItem {...link} key={link.label} base={base} />
  ));
  return <>{links}</>;
}
export default NavLinks;
