import {
  Box,
  Card,
  Group,
  Indicator,
  Stack,
  Text,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core";
import { IconAward } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { subDays } from "date-fns";
import { useRouter } from "next/router";
import ReactTimeago from "react-timeago";
import {
  listAchievementDefinitions,
  listNotifications,
  updateNotification,
} from "../../common/api";
import { Board } from "../../common/boards/board.schema";
import { Event } from "../../common/events/event.schema";
import {
  Notification,
  NotificationType,
} from "../../common/notifications/notification.schema";
import { useAuthStore } from "../../common/stores/auth.store";
import { User } from "../../common/users/user.schema";
import { getBoardLink, getEventLink, getMemberLink } from "../../common/util";
import InfiniteList, { RenderFunction } from "../common/InfiniteList";
import { Member } from "../../common/members/member.schema";
const NotificationListItem = ({
  notification,
  onClick: handleClick,
}: {
  notification: Notification;
  onClick: () => void;
}) => {
  const creator = notification.creator as User;
  const router = useRouter();
  const board = notification.board as Board;
  const event = notification.tournament as Event;
  const member = notification.member as Member;
  const user = useAuthStore((s) => s.user);
  const { mutate } = useMutation(updateNotification);
  const queryClient = useQueryClient();
  const { data: achievementDefinitions } = useQuery(
    ["achievement-definitions"],
    listAchievementDefinitions
  );
  const onClick = () => {
    mutate(
      {
        _id: notification._id,
        read: true,
      },
      {
        onSuccess: () =>
          queryClient.invalidateQueries(["unread-notifications"]),
      }
    );
    handleClick();
    if (board && member) return router.push(getMemberLink(board, member));
    if (board && event) return router.push(getEventLink(board, event));
    if (board && notification.type == NotificationType.ACHIEVEMENT_COMPLETE)
      return router.push(`${getBoardLink(board)}/achievements`);
    if (board) return router.push(getBoardLink(board));
  };
  const getContent = () => {
    switch (notification.type) {
      case NotificationType.BOARD_MEMBER_INVITE:
      case NotificationType.BOARD_USER_INVITE:
        return (
          <>
            {creator.name} {creator.lastName} invited you to {board.name}{" "}
            <ReactTimeago date={notification.createdAt} />{" "}
          </>
        );
      case NotificationType.TOURNAMENT_REFEREE_INVITE:
      case NotificationType.TOURNAMENT_REFEREE_ADDED:
        return (
          <>
            {creator.name} {creator.lastName} added you as referee of{" "}
            {event.name} <ReactTimeago date={notification.createdAt} />
          </>
        );
      case NotificationType.BOARD_ADMIN_INVITE:
      case NotificationType.BOARD_ADMIN_ADDED:
        return (
          <>
            {creator.name} {creator.lastName} added you as admin of {board.name}{" "}
            <ReactTimeago date={notification.createdAt} />
          </>
        );
      case NotificationType.BOARD_REFEREE_INVITE:
      case NotificationType.BOARD_REFEREE_ADDED:
        return (
          <>
            {creator.name} {creator.lastName} added you as referee of{" "}
            {board.name} <ReactTimeago date={notification.createdAt} />
          </>
        );
      case NotificationType.WELCOME:
        return <>Welcome to Scoreboarder {user?.name}!</>;
      case NotificationType.TEST:
        return <>This is a test notification from {creator?.name}!</>;
      case NotificationType.MATCH_ADDED:
        return (
          <>
            {creator.name} {creator.lastName} added a match in {board.name}{" "}
            <ReactTimeago date={notification.createdAt} />
          </>
        );
      case NotificationType.MATCH_APPROVAL_REQUIRED:
        return (
          <>
            {creator.name} {creator.lastName} added a match in {board.name} that
            requires approval <ReactTimeago date={notification.createdAt} />
          </>
        );
      case NotificationType.MATCH_APPROVED:
        return (
          <>
            your match in {board.name} was approved{" "}
            <ReactTimeago date={notification.createdAt} />
          </>
        );
      case NotificationType.TOURNAMENT_REGISTRATION:
        return (
          <>
            {creator.name} registered for your event &quot;
            {event.name}&quot; in {board.name}{" "}
            <ReactTimeago date={notification.createdAt} />
          </>
        );
      case NotificationType.TOURNAMENT_REGISTRATION_PENDING:
        return (
          <>
            {creator.name} requires approval for your event &quot;
            {event.name}&quot; in {board.name}{" "}
            <ReactTimeago date={notification.createdAt} />
          </>
        );
      case NotificationType.TOURNAMENT_REGISTRATION_APPROVED:
        return (
          <>
            Your registration for &quot;
            {event.name}&quot; in {board.name} was approved{" "}
            <ReactTimeago date={notification.createdAt} />
          </>
        );
      case NotificationType.TOURNAMENT_REGISTRATION_PAID:
        return (
          <>
            {creator.name} has paid for your event &quot;
            {event.name}&quot; in {board.name}{" "}
            <ReactTimeago date={notification.createdAt} />
          </>
        );
      case NotificationType.TOURNAMENT_COMPLETE:
        const r = event.registrations.find((r) => r.user === user?._id);
        let result = 0;
        let resultText = "last in";
        if (r && r.user === user?._id) {
          const m = r.member;
          result = (event.results as string[]).indexOf(m as string) + 1;
        }
        resultText = `${result}th`;
        if (result === 1) resultText = "1st";
        if (result === 2) resultText = "2nd";
        if (result === 3) resultText = "3rd";
        return (
          <>
            You finished {resultText} in event &quot;
            {event.name}&quot; <ReactTimeago date={notification.createdAt} />
          </>
        );
      case NotificationType.ACHIEVEMENT_COMPLETE:
        const achievementDefinition = achievementDefinitions?.records.find(
          (ad) => ad.id === notification.achievement
        );
        return (
          <Group wrap="nowrap">
            <ThemeIcon variant="light" color="green">
              <IconAward />
            </ThemeIcon>
            <Text>
              Achieved &quot;{achievementDefinition?.name}&quot; in {board.name}{" "}
              <ReactTimeago date={notification.createdAt} />
            </Text>
          </Group>
        );
      case NotificationType.MEMBER_CLAIM_RECEIVED:
        return (
          <>
            {creator.email} requested approval for their claim of member profile{" "}
            &quot;{member.name} {member.lastName}&quot; in {board?.name}{" "}
            <ReactTimeago date={notification.createdAt} />
          </>
        );
        break;
      case NotificationType.MEMBER_CLAIM_APPROVED:
        return (
          <>
            Your claim of {member.name} in {board?.name} was approved{" "}
            <ReactTimeago date={notification.createdAt} />
          </>
        );
        break;
      case NotificationType.MEMBER_CLAIM_REJECTED:
        return (
          <>
            Your claim of {member.name} in {board?.name} was rejected{" "}
            <ReactTimeago date={notification.createdAt} />
          </>
        );
        break;
    }
  };
  return (
    <UnstyledButton onClick={onClick}>
      <Indicator
        offset={10}
        size={6}
        position="top-end"
        disabled={notification.read}
      >
        <Card>
          <Group wrap="nowrap">
            <Box>
              <Text size="sm">{getContent()}</Text>
            </Box>
          </Group>
        </Card>
      </Indicator>
    </UnstyledButton>
  );
};
const MONTH = 30;
const NotificatonList = ({ onClick }: { onClick: () => void }) => {
  const user = useAuthStore((s) => s.user);
  const renderFunction = (notification: Notification) => {
    // console.log("match :", match);
    // console.log("match._id :", match._id);
    return (
      <NotificationListItem onClick={onClick} notification={notification} />
    );
  };
  const doListNotifications = async (offset: number, search?: string) => {
    const params: any = {
      user: user?._id,
      createdAt: { $gt: subDays(new Date(), MONTH) },
    };

    return listNotifications({
      offset,
      search,
      params,
      populate: ["board", "match", "member", "creator", "tournament"],
    });
  };
  return (
    <Stack px={10}>
      {user && (
        <InfiniteList
          cols={1}
          spacing={{ base: "sm", md: "md" }}
          listFunction={doListNotifications}
          renderFunction={renderFunction as RenderFunction}
          queryKey={["notifications"]}
          emptyComponent={
            <Stack>
              <Text size="sm" ta="center">
                No recent notifications
              </Text>
            </Stack>
          }
        />
      )}
    </Stack>
  );
};
export default NotificatonList;
