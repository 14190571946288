import {
  IconAward,
  IconCalendarEvent,
  IconCoin,
  IconListNumbers,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import { useBoard, useIsBoardAdmin } from "../../common/boards/board.hooks";
import { Swords } from "../../common/icons/swords";
import { getBoardLink } from "../../common/util";
import NavLinks from "./NavLinks";
const ICON_SIZE = 20;

export function MainLinks() {
  const { t } = useTranslation();
  const data = [
    {
      icon: <IconListNumbers size={ICON_SIZE} />,
      color: "blue",
      label: t("ranks"),
      href: "",
      className: "leaderboard",
    },
    {
      icon: <Swords size={ICON_SIZE} />,
      color: "teal",
      label: t("matches"),
      href: "/matches",
      className: "matches",
    },
    {
      icon: <IconUser size={ICON_SIZE} />,
      color: "violet",
      label: t("members"),
      href: "/members",
      className: "members",
    },
    {
      icon: <IconCalendarEvent size={ICON_SIZE} />,
      color: "yellow",
      label: t("events"),
      href: "/events",
      className: "events",
    },
  ];
  const { board } = useBoard();
  const isAdmin = useIsBoardAdmin();
  // console.log("isAdmin :", isAdmin);
  const hasMember = true;
  if (!board) return <></>;
  const displayData = [...data];
  if (hasMember)
    displayData.push({
      icon: <IconAward size={ICON_SIZE} />,
      color: "grape",
      label: t("achievements"),
      href: "/achievements",
      className: "achievements",
    });
  if (isAdmin) {
    displayData.push({
      icon: <IconCoin size={ICON_SIZE} />,
      color: "grape",
      label: t("payments"),
      href: "/payments",
      className: "payments",
    });
    displayData.push({
      icon: <IconSettings size={ICON_SIZE} />,
      color: "grape",
      label: t("settings"),
      href: "/settings",
      className: "settings",
    });
  }

  return <NavLinks data={displayData} base={getBoardLink(board)} />;
}
