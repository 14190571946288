import { AppShell, Box } from "@mantine/core";
import { useRouter } from "next/router";
import { ReactNode } from "react";
// import { useAppStore } from "../../common/stores/app.store";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import classes from "./AppShell.module.css";
interface Props {
  children: ReactNode;
}
const AppShellComp = ({ children }: Props) => {
  const router = useRouter();
  const hasNav =
    router.pathname.includes("/boards/") || router.pathname.includes("/admin");
  const padding = hasNav ? { base: "xs", xs: "xs", sm: "sm", md: "md" } : 0;
  // const isPwa = useAppStore((s) => s.isPwa);
  const screenshot = router.asPath.indexOf("/screenshots") === 0;
  return (
    <AppShell
      className={classes.appShell}
      header={{ height: 70 }}
      navbar={{
        width: hasNav ? { xs: 80, md: 170 } : 0,
        breakpoint: "xs",
      }}
      // footer={{ height: { base: 170, sm: 90 } }}
      px={padding}
      pt={{ base: "xs", sm: "sm", md: "md" }}
      // styles={(theme: MantineTheme) => ({
      //   main: {
      //     WebkitTouchCallout: isPwa ? "none" : "auto",
      //     WebkitUserSelect: isPwa ? "none" : "auto",
      //     WebkitTapHighlightColor: isPwa ? "transparent" : "auto",
      //     backgroundColor:
      //       theme.colorScheme === "dark"
      //         ? theme.colors.dark[8]
      //         : theme.colors.gray[0],
      //   } as CSSObject,
      // })}
      // fixed
      // navbarOffsetBreakpoint="xs"
    >
      {screenshot ? <></> : <Header />}
      {screenshot ? <></> : <Navbar />}
      <AppShell.Main>
        <Box style={{ paddingBottom: 90 }}>{children}</Box>
      </AppShell.Main>
      {screenshot ? <></> : <Footer />}
    </AppShell>
  );
};
export default AppShellComp;
