import { Box, MantineBreakpoint } from "@mantine/core";
import { ReactNode } from "react";

const Hider = ({
  children,
  largerThan,
  smallerThan,
  disabled,
}: {
  children: ReactNode;
  largerThan?: MantineBreakpoint;
  smallerThan?: MantineBreakpoint;
  disabled?: boolean;
}) => (
  <Box
    visibleFrom={disabled ? undefined : smallerThan}
    hiddenFrom={disabled ? undefined : largerThan}
  >
    {children}
  </Box>
);
export default Hider;
