import {
  ActionIcon,
  ActionIconProps,
  useComputedColorScheme,
  useMantineColorScheme,
} from "@mantine/core";
import { IconMoon, IconSun } from "@tabler/icons-react";
import { useEffect, useState } from "react";

const SchemeToggle = (props: ActionIconProps) => {
  const [schemeIcon, setSchemeIcon] = useState(<IconMoon size={18} />);
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme();

  useEffect(() => {
    switch (computedColorScheme) {
      case "dark":
        setSchemeIcon(<IconSun size={18} />);
        break;
      case "light":
        setSchemeIcon(<IconMoon size={18} />);
        break;
      default:
        setSchemeIcon(<IconMoon size={18} />);
    }
  }, [computedColorScheme]);
  const toggleDark = () => {
    switch (computedColorScheme) {
      case "dark":
        setColorScheme("light");
        break;
      case "light":
        setColorScheme("dark");
        break;
      default:
        setColorScheme("dark");
    }
  };
  //   let schemeIcon = <IconShadow size={18} />;
  //   if (colorScheme === "dark") {
  //     schemeIcon = <IconMoon size={18} />;
  //   }
  //   if (colorScheme === "light") {
  //     schemeIcon = <IconSun size={18} />;
  //   }
  return (
    <ActionIcon
      {...props}
      variant="transparent"
      color="dark"
      aria-label="dark mode"
      onClick={toggleDark}
    >
      {schemeIcon}
    </ActionIcon>
  );
};
export default SchemeToggle;
