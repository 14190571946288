import {
  ActionIcon,
  Anchor,
  AppShell,
  Avatar,
  Box,
  Button,
  Divider,
  Group,
  Menu,
  Transition,
  UnstyledButton,
} from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronsUp,
  IconCoin,
  IconCurrencyDollar,
  IconDeviceMobile,
  IconLogout,
  IconQuestionMark,
  IconSettings,
} from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { isAndroid } from "react-device-detect";
import { useBoard } from "../../common/boards/board.hooks";
import { useAppStore } from "../../common/stores/app.store";
// import { useRequireAuth } from "../common/auth/useRequireAuth"
import { useAuthStore } from "../../common/stores/auth.store";
import { getInitials, PLAY_STORE_LINK } from "../../common/util";
import BoardLink from "../boards/BoardLink";
import Hider from "../common/Hider";
import SchemeToggle from "../common/SchemeToggle";
import NotificationsButton from "../notifications/NotificationsButton";
import classes from "./Header.module.css";
import Logo from "./Logo";
// const FRESHDESK_URL = "https://scoreboarder.freshdesk.com/support/home";
const HeaderComp = () => {
  const { t } = useTranslation(["common"]);
  // const toggleOpen = useAppStore((state) => state.toggleOpen);
  const user = useAuthStore((state) => state.user);
  const token = useAuthStore((state) => state.token);
  // console.log("user :", user);
  const { board } = useBoard();
  const logout = useAuthStore((state) => state.logout);
  const [showBack, setShowBack] = useState(false);
  const router = useRouter();
  const isLogin =
    router.pathname.indexOf("/login") === 0 ||
    router.pathname.indexOf("/pwa-home") === 0;
  const isBoard = router.pathname.indexOf("/boards") === 0;
  // console.log("isBoard :", isBoard);

  const [userChecked, setUserChecked] = useState(false);
  useTimeout(() => setUserChecked(true), 1000, {
    autoInvoke: true,
  });
  useEffect(() => {
    if (token) setUserChecked(true);
  }, [token]);
  const doLogout = () => {
    logout();
    router.push(isPwa ? "pwa-home" : "/");
  };
  const isPwa = useAppStore((s) => s.isPwa);
  useEffect(() => {
    const newShowBack =
      router.asPath !== "/" &&
      router.asPath !== "/dashboard" &&
      router.asPath !== "/pwa-home";
    setShowBack(newShowBack);
  }, [router.asPath]);
  // const showBack =
  //   router.asPath !== "/" &&
  //   router.asPath !== "/dashboard" &&
  //   router.asPath !== "/pwa-home";
  const noLinksWidth = isBoard ? "sm" : "xs";
  const deep =
    router.asPath !== "/" &&
    router.asPath !== "/dashboard" &&
    router.asPath !== "/pwa-home";
  // console.log("router.asPath :", router.asPath);
  const registerLink = deep
    ? `/login?state=register&redirect=${encodeURIComponent(router.asPath)}`
    : `/login?state=register`;
  const loginLink = deep
    ? `/login?redirect=${encodeURIComponent(router.asPath)}`
    : `/login?`;
  // const open = useAppStore((state) => state.open);
  // const theme = useMantineTheme();
  if (router.asPath === "/pwa-home") return null;
  return (
    <AppShell.Header className={classes.root}>
      <Group
        style={{ height: "100%" }}
        px={20}
        justify="space-between"
        wrap="nowrap"
      >
        <Group pt={6} wrap="nowrap">
          <Transition transition="slide-right" mounted={showBack}>
            {(transitionStyles) => (
              <ActionIcon
                variant="transparent"
                style={transitionStyles}
                onClick={() => router.back()}
              >
                <IconChevronLeft />
              </ActionIcon>
            )}
          </Transition>
          <Logo />
          <Group pt={2}>{board && <BoardLink board={board} />}</Group>
        </Group>
        <Transition
          mounted={userChecked}
          transition="fade"
          duration={400}
          timingFunction="ease"
        >
          {(styles) => (
            <Group style={styles} wrap="nowrap">
              {!token && !isLogin && (
                <>
                  <Hider smallerThan={noLinksWidth}>
                    <Group>
                      <Anchor size="sm" component={Link} href="/pricing">
                        {t("pricing")}
                      </Anchor>
                    </Group>
                  </Hider>
                  <Hider smallerThan={noLinksWidth}>
                    <Group>
                      <Anchor size="sm" component={Link} href="/support">
                        {t("support")}
                      </Anchor>
                    </Group>
                  </Hider>
                  <Button size="xs" component={Link} href={registerLink}>
                    {t("signUp")}
                  </Button>
                  <Hider smallerThan="xs">
                    <Box>
                      <Anchor size="sm" component={Link} href={loginLink}>
                        {t("orLogin")}
                      </Anchor>
                    </Box>
                  </Hider>
                </>
              )}
              <SchemeToggle size="xs" />
              {user && <NotificationsButton size="xs" mt={5} />}
              {user && (
                <Menu
                  width={260}
                  position="bottom-end"
                  transitionProps={{ transition: "pop-top-right" }}
                >
                  <Menu.Target>
                    <UnstyledButton className="user-menu">
                      <Group gap={7}>
                        <Avatar src={user.image} alt={user.name} size={20}>
                          {getInitials(user.name)}
                        </Avatar>
                        <IconChevronDown size={12} stroke={1.5} />
                      </Group>
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item>{user.name}</Menu.Item>

                    <Menu.Item
                      leftSection={<IconSettings size={14} stroke={1.5} />}
                      component={Link}
                      href="/settings"
                    >
                      {t("accountSettings")}
                    </Menu.Item>

                    {user.admin && (
                      <Menu.Item
                        component={Link}
                        href="/admin"
                        leftSection={<IconChevronsUp size={14} stroke={1.5} />}
                      >
                        {t("admin")}
                      </Menu.Item>
                    )}

                    <Menu.Item
                      component={Link}
                      href="/billing"
                      leftSection={
                        <IconCurrencyDollar size={14} stroke={1.5} />
                      }
                    >
                      {t("plans")}
                    </Menu.Item>
                    <Menu.Item
                      component={Link}
                      href="/payments"
                      leftSection={<IconCoin size={14} stroke={1.5} />}
                    >
                      {t("myPayments")}
                    </Menu.Item>

                    {!isPwa && (
                      <Menu.Item
                        component={Link}
                        href={isAndroid ? PLAY_STORE_LINK : "/app"}
                        target={isAndroid ? "_blank" : "_self"}
                        leftSection={
                          <IconDeviceMobile size={14} stroke={1.5} />
                        }
                      >
                        {t("installApp")}
                      </Menu.Item>
                    )}
                    <Menu.Item
                      component={Link}
                      href="/support"
                      leftSection={<IconQuestionMark size={14} stroke={1.5} />}
                    >
                      {t("support")}
                    </Menu.Item>
                    <Divider mt={5} mb={20} />
                    <Menu.Item
                      className="logout"
                      onClick={doLogout}
                      leftSection={<IconLogout size={14} stroke={1.5} />}
                    >
                      {t("logout")}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
            </Group>
          )}
        </Transition>
      </Group>
    </AppShell.Header>
  );
};
export default HeaderComp;
