import { showNotification } from "@mantine/notifications";
import * as Sentry from "@sentry/nextjs";
import HttpError from "standard-http-error";
import {
  Achievement,
  AchievementDefinition,
} from "./achievements/achievements.schema";
import { identify, trackEvent } from "./analytics";
import { ForgotPasswordDto } from "./auth/dto/forgot-password.dto";
import { LoginResponseDto } from "./auth/dto/login-response.dto";
import { LoginDto } from "./auth/dto/login.dto";
import { RegisterResponseDto } from "./auth/dto/register-response.dto";
import { RegisterDto } from "./auth/dto/register.dto";
import { ResetPasswordDto } from "./auth/dto/reset-password.dto";
import { Board } from "./boards/board.schema";
import { AddAdminDto } from "./boards/dto/add-admin.dto";
import { AddRefereeDto } from "./boards/dto/add-referee.dto";
import { CreateBoardDto } from "./boards/dto/create-board.dto";
import { RemoveAdminDto } from "./boards/dto/remove-admin.dto";
import { RemoveRefereeDto } from "./boards/dto/remove-referee.dto";
import { UpdateBoardDto } from "./boards/dto/update-board.dto";
import { AddEventMembersDto } from "./events/dto/add-event-members.dto";
import { AddTournamentRefereeDto } from "./events/dto/add-event-referee.dto";
import { CreateEventDto } from "./events/dto/create-event.dto";
import { RemoveEventRefereeDto } from "./events/dto/remove-event-referee.dto";
import { UpdateEventRegistrationDto } from "./events/dto/update-event-registration.dto";
import { UpdateEventDto } from "./events/dto/update-event.dto";
import { WithdrawMemberDto } from "./events/dto/withdraw-member.dto";
import { Event } from "./events/event.schema";
import { CreateMatchDto } from "./matches/dto/create-match.dto";
import { UpdateMatchDto } from "./matches/dto/update-match.dto";
import { Match } from "./matches/match.schema";
import { CreateMemberDto } from "./members/dto/create-member.dto";
import { UpdateMemberDto } from "./members/dto/update-member.dto";
import { Member, MemberStats, UsfaData } from "./members/member.schema";
import { UpdateNotificationDto } from "./notifications/dto/update-notification";
import {
  Notification,
  NotificationType,
} from "./notifications/notification.schema";
import { CreateOrganisationDto } from "./organisations/create-organisation.dto";
import { Organisation } from "./organisations/organisation.schema";
import { UpdateOrganisationDto } from "./organisations/update-organisation.dto";
import { useAuthStore } from "./stores/auth.store";
import { CreateSubscriptionDto } from "./stripe/dto/create-subscription.dto";
import { UploadImageDto } from "./upload-image.dto";
import { UpdateUserDto } from "./users/dto/update-user.dto";
import { PlanType, User } from "./users/user.schema";
const apiUrl = `${process.env.NEXT_PUBLIC_API}/api/v1`;
export const PAGE_SIZE = 100;
export const SMALL_PAGE_SIZE = 20;
export const MAX_PAGE_SIZE = 9999;
export const HALF_HOUR = 1000 * 60 * 30;
export const FIVE_MINUTES = 1000 * 60 * 5;
export interface ListParams {
  offset?: number;
  search?: string;
  params?: any;
  limit?: number;
  sort?: string;
  populate?: string[];
}
export interface ImageResponse {
  key: string;
  url: string;
}
export interface OpponentStats {
  wins: number;
  losses: number;
  matches: number;
}
export interface BoardStats {
  matchCount: number;
  memberCount: number;
  tournamentCount: number;
  lastMatch?: Date;
  lastMember?: Date;
  lastEvent?: Date;
}
export async function register(dto: RegisterDto) {
  // console.log("register :", dto);
  const res = await post("/auth/register", dto);
  // trackEvent("sign_up"); // do this in screen to have token in place
  const resDto = res.body as RegisterResponseDto;
  // const user = resDto.user;
  // identify(user._id, user);
  return resDto;
}
export async function login(dto: LoginDto) {
  // console.log("login :", dto);
  const res = await post("/auth/login", dto);
  // trackEvent("login"); // do this in screen to have token in place
  const resDto = res.body as LoginResponseDto;
  // const user = resDto.user;
  // identify(user._id, user);
  return resDto;
}
export async function getRefreshToken() {
  const res = await get(`/auth/refresh`);
  const { token } = res.body as { token: string };
  return token;
}
export async function getMe() {
  const res = await get(`/me`);
  const user = res.body as User;
  return user;
}
export async function forgot(dto: ForgotPasswordDto) {
  const res = await post("/auth/forgot", dto);
  return res.body;
}
export async function reset(dto: ResetPasswordDto) {
  const res = await post("/auth/reset", dto);
  return res.body as LoginResponseDto;
}

export interface ListUsersResult {
  records: User[];
  count: number;
  offset: number;
}
export async function listUsers({
  offset = 0,
  search = "",
  params = {},
  limit = PAGE_SIZE,
  sort = "name",
}: ListParams): Promise<ListUsersResult> {
  // console.log("listBoards :", offset, search, mine);
  let url = `/users?skip=${offset}&limit=${limit}&sort=${sort}`;
  if (search !== "") url += `&search=${search}`;
  const queryString = JSON.stringify(params);
  if (queryString.length > 2) url += `&query=${queryString}`;
  const res = await get(url);
  // console.log("res :", res);
  const records = res.body as User[];
  const countString = res.headers.get("x-total-count");
  // console.log("countString :", countString);
  const count = countString ? parseInt(countString) : 0;
  return { records, count, offset };
}
export async function getUser(id: string) {
  // console.log("shortLink :", shortLink);
  const res = await get(`/users/${id}`);
  return res.body as User;
}
export async function updateUser(dto: UpdateUserDto) {
  const id = dto._id;
  const res = await patch(`/users/${id}`, dto);
  trackEvent("update_user");
  const user = res.body as User;
  identify(user);
  return user;
}
export async function deleteUser(id: string) {
  await del(`/users/${id}`);
  trackEvent("delete_user");
}
export async function uploadUserImage(dto: UploadImageDto) {
  const res = await upload(`/users/${dto.id}/image`, dto.image);
  trackEvent("update_board_image");
  // console.log("res.body :", res.body);
  return res.body as ImageResponse;
}
export async function getOrganisation(id: string) {
  const res = await get(`/organisations/${id}`);
  return res.body as Organisation;
}
export async function createOrganisation(dto: CreateOrganisationDto) {
  const res = await post("/organisations", dto);
  trackEvent("create_organisation");
  return res.body as Organisation;
}
export interface ListOrganisationsResult {
  records: Organisation[];
  count: number;
  offset: number;
}
export async function listOrganisations({
  offset = 0,
  search = "",
  params = {},
  limit = PAGE_SIZE,
  sort = "name",
  populate = [],
}: ListParams): Promise<ListOrganisationsResult> {
  // console.log("listBoards :", offset, search, mine);
  let url = `/organisations?skip=${offset}&limit=${limit}&sort=${sort}`;
  if (search !== "") url += `&search=${search}`;
  if (params?.mine === true) {
    url += `&mine=1`;
    delete params.mine;
  }
  // console.log("params :", params);
  const queryString = JSON.stringify(params);
  if (queryString.length > 2) url += `&query=${queryString}`;
  if (populate?.length > 0) url += `&populate=${populate.join()}`;
  const res = await get(url);
  // console.log("res :", res);
  const records = res.body as Organisation[];
  const countString = res.headers.get("x-total-count");
  // console.log("countString :", countString);
  const count = countString ? parseInt(countString) : 0;
  return { records, count, offset };
}
export async function updateOrganisation(dto: UpdateOrganisationDto) {
  const id = dto._id;
  const res = await patch(`/organisations/${id}`, dto);
  trackEvent("update_organisation");
  const organisation = res.body as Organisation;
  return organisation;
}
export interface ListBoardsResult {
  records: Board[];
  count: number;
  offset: number;
}
export async function listBoards({
  offset = 0,
  search = "",
  params = {},
  limit = PAGE_SIZE,
  sort = "name",
}: ListParams): Promise<ListBoardsResult> {
  // console.log("params :", params);
  // console.log("listBoards :", offset, search, params);
  let url = `/boards?skip=${offset}&limit=${limit}&sort=${sort}`;
  if (search !== "") url += `&search=${search}`;
  if (params?.mine === true) {
    url += `&mine=1`;
    delete params.mine;
  }
  const queryString = JSON.stringify(params);
  // console.log("queryString :", queryString);
  if (queryString.length > 2) url += `&query=${queryString}`;
  const res = await get(url);
  // console.log("res :", res);
  const records = res.body as Board[];
  const countString = res.headers.get("x-total-count");
  // console.log("countString :", countString);
  const count = countString ? parseInt(countString) : 0;
  return { records, count, offset };
}
export async function getBoard(shortLink: string) {
  // console.log("shortLink :", shortLink);
  const query = { shortLink };
  const res = await get(
    `/boards?query=${JSON.stringify(query)}&populate=organisation`
  );
  if (res.body.length === 0) throw new Error("Board not found");
  const list = res.body as Board[];
  return list[0];
}
export async function getBoardById(id: string) {
  // console.log("shortLink :", shortLink);
  const res = await get(`/boards/${id}`);
  return res.body as Board;
}
export async function createBoard(dto: CreateBoardDto) {
  const res = await post("/boards", dto);
  trackEvent("create_board");
  identify({ isOwner: true });
  return res.body as Board;
}
export async function joinBoard(code: string) {
  const res = await post("/boards/join", { code });
  trackEvent("join_board");
  return res.body as Member;
}

export async function updateBoard(dto: UpdateBoardDto) {
  const id = dto._id;
  const res = await patch(`/boards/${id}`, dto);
  trackEvent("update_board", { board: dto._id });
  return res.body as Board;
}
export async function refreshBoard(id: string) {
  const res = await post(`/boards/${id}/members/refresh`, {});
  trackEvent("refresh_board", { board: id });
  return res.body as Board;
}
export async function addBoardAdmin(dto: AddAdminDto) {
  const id = dto.boardId;
  const res = await post(`/boards/${id}/admins`, dto);
  trackEvent("add_board_admin", { board: dto.boardId });
  trackEvent("generate_lead");
  return res.body as Board;
}
export async function removeBoardAdmin(dto: RemoveAdminDto) {
  await del(`/boards/${dto.board}/admins/${dto.admin}`);
  trackEvent("remove_board_admin", { board: dto.board });
}
export async function addBoardReferee(dto: AddRefereeDto) {
  const id = dto.boardId;
  const res = await post(`/boards/${id}/referees`, dto);
  trackEvent("add_board_referee", { board: dto.boardId });
  trackEvent("generate_lead");
  return res.body as Board;
}
export async function removeBoardReferee(dto: RemoveRefereeDto) {
  await del(`/boards/${dto.board}/referees/${dto.referee}`);
  trackEvent("remove_board_referee", { board: dto.board });
}
export async function removeEventReferee(dto: RemoveEventRefereeDto) {
  await del(`/tournaments/${dto.event}/referees/${dto.referee}`);
  trackEvent("remove_event_referee", { event: dto.event });
}
export async function uploadBoardImage(dto: UploadImageDto) {
  const res = await upload(`/boards/${dto.id}/image`, dto.image);
  trackEvent("update_board_image", { board: dto.id });
  // console.log("res.body :", res.body);
  return res.body as ImageResponse;
}
export async function getBoardStats(id: string) {
  // console.log("shortLink :", shortLink);
  const res = await get(`/boards/${id}/stats`);
  return res.body as BoardStats;
}
export interface ListMembersResult {
  records: Member[];
  count: number;
  offset: number;
}
export async function listMembers({
  offset = 0,
  search = "",
  params = {},
  limit = PAGE_SIZE,
  sort = "-lastPlayed",
  populate = [],
}: ListParams): Promise<ListMembersResult> {
  // console.log("listMembers :", offset, search);
  let url = `/members?skip=${offset}&limit=${limit}&sort=${sort}`;
  if (search !== "") url += `&search=${search}`;
  const queryString = JSON.stringify(params);
  if (queryString.length > 2) url += `&query=${queryString}`;
  if (populate?.length > 0) url += `&populate=${populate.join()}`;
  const res = await get(url);
  // console.log("res :", res);
  const records = res.body as Member[];
  // console.log("records :", records);
  const countString = res.headers.get("x-total-count");
  // console.log("countString :", countString);
  const count = countString ? parseInt(countString) : 0;
  return { records, count, offset };
}
export async function createMember(dto: CreateMemberDto) {
  const res = await post("/members", dto);
  trackEvent("create_member", { board: dto.board });
  return res.body as Member;
}
export async function mergeMember({
  memberId1,
  memberId2,
}: {
  memberId1: string;
  memberId2: string;
}) {
  const res = await post(`/members/${memberId1}/merge`, { member: memberId2 });
  trackEvent("merge_member", { member: memberId1 });
  return res.body as Member;
}
export interface ImportMembersDto {
  boardId: string;
  members: CreateMemberDto[];
}
export interface ImportMembersResult {
  members: Member[];
  created: string[];
  existing: string[];
  errors: { status: number; error: string; member: CreateMemberDto }[];
}
export async function importMembers(dto: ImportMembersDto) {
  const res = await post(`/boards/${dto.boardId}/members/import`, dto.members);
  trackEvent("import_members", { board: dto.boardId });
  return res.body as ImportMembersResult;
}
export async function updateMember(dto: UpdateMemberDto) {
  const id = dto._id;
  const res = await patch(`/members/${id}`, dto);
  trackEvent("update_member", { member: dto._id });
  if (dto.email) {
    trackEvent("generate_lead");
  }
  return res.body as Member;
}
export async function uploadMemberImage(dto: UploadImageDto) {
  const res = await upload(`/members/${dto.id}/image`, dto.image);
  trackEvent("update_member_image", { member: dto.id });
  // console.log("res.body :", res.body);
  return res.body as ImageResponse;
}
export async function getMember(shortLink: string) {
  // console.log("shortLink :", shortLink);
  const query = { shortLink };
  const res = await get(`/members?team=all&query=${JSON.stringify(query)}`);
  const list = res.body as Member[];
  return list[0];
}
export async function getMemberByUser(userId: string, boardId: string) {
  // console.log("shortLink :", shortLink);
  const query = { user: userId, board: boardId };
  const res = await get(`/members?team=all&query=${JSON.stringify(query)}`);
  const list = res.body as Member[];
  return list[0];
}
export async function getMemberById(id: string) {
  // console.log("shortLink :", shortLink);
  const res = await get(`/members/${id}`);
  return res.body as Member;
}
export async function getMemberStats(id: string) {
  // console.log("shortLink :", shortLink);
  const res = await get(`/members/${id}/stats`);
  return res.body as MemberStats;
}
export async function getOpponentStats(id: string, opponent: string) {
  if (!opponent) return;
  const res = await get(`/members/${id}/opponents/${opponent}`);
  return res.body as OpponentStats;
}
export async function createMemberClaim(id: string) {
  const res = await post(`/members/${id}/claims`, {});
  trackEvent("claim_member_sent", { member: id });
  return res.body as Member;
}
export async function updateMemberClaim(dto: {
  memberId: string;
  userId: string;
  status: "approved" | "rejected";
}) {
  const res = await patch(`/members/${dto.memberId}/claims/${dto.userId}`, {
    status: dto.status,
  });
  if (dto.status === "approved") {
    trackEvent("claim_member_approved", { member: dto.memberId });
  } else {
    trackEvent("claim_member_rejected", { member: dto.memberId });
  }
  return res.body as Member;
}
export async function usfaSearch(id: string) {
  if (!id.length) return;
  const res = await get(`/members/search-usfa/${id}`);
  return res.body as UsfaData;
}
export interface ListResult {
  records: any[];
  count: number;
  offset: number;
}
export interface ListMatchesResult {
  records: Match[];
  count: number;
  offset: number;
}
export async function listMatches({
  offset = 0,
  search = "",
  params = {},
  limit = PAGE_SIZE,
  populate = [],
  sort = "-createdAt",
}: ListParams): Promise<ListMatchesResult> {
  // console.log("listBoards :", offset, search, mine);
  populate.push("members");
  let url = `/matches?skip=${offset}&limit=${limit}sort=${sort}&populate=${populate.join()}`;
  if (search !== "") url += `&search=${search}`;
  const queryString = JSON.stringify(params);
  if (queryString.length > 2) url += `&query=${queryString}`;
  const res = await get(url);
  // console.log("res :", res);
  const records = res.body as Match[];
  const countString = res.headers.get("x-total-count");
  // console.log("countString :", countString);
  const count = countString ? parseInt(countString) : 0;
  return { records, count, offset };
}
export interface ListNotificationsResult {
  records: Notification[];
  count: number;
  offset: number;
}
export interface CreateNotificationDto {
  user: string;
  type: NotificationType;
  board?: string;
  message?: string;
  link?: string;
}
export async function createNotification(dto: CreateNotificationDto) {
  const res = await post("/notifications", dto);
  trackEvent("create_notification", { board: dto.board });
  return res.body as Notification;
}
export async function listNotifications({
  offset = 0,
  search = "",
  params = {},
  limit = PAGE_SIZE,
  populate = [],
  sort = "-createdAt",
}: ListParams): Promise<ListNotificationsResult> {
  // console.log("listNotifications :", offset, search);
  let url = `/notifications?skip=${offset}&limit=${limit}sort=${sort}&populate=${populate.join()}`;
  if (search !== "") url += `&search=${search}`;
  const queryString = JSON.stringify(params);
  if (queryString.length > 2) url += `&query=${queryString}`;
  const res = await get(url);
  // console.log("res :", res);
  const records = res.body as Notification[];
  const countString = res.headers.get("x-total-count");
  // console.log("countString :", countString);
  const count = countString ? parseInt(countString) : 0;
  return { records, count, offset };
}
export async function updateNotification(dto: UpdateNotificationDto) {
  const res = await patch(`/notifications/${dto._id}`, dto);
  trackEvent("update_notification", { notification: dto._id });
  return res.body as Notification;
}
export async function readAll() {
  const res = await post(`/notifications/read-all`, {});
  trackEvent("read_all_notifications");
  return res.body;
}
export interface MatchSeriesParams {
  member?: Member;
  board?: Board;
  start?: Date;
  end?: Date;
  tick?: number;
}
export interface MatchSeriesItem {
  date: Date;
  count: number;
  score?: number;
}
export interface MatchSeriesResult {
  items: MatchSeriesItem[];
}
export async function getMatchSeries({
  member,
  board,
  tick,
  start,
  end,
}: MatchSeriesParams) {
  let url = `/matches/timeseries?`;
  if (member) url += `&member=${member._id}`;
  if (board) url += `&board=${board._id}`;
  if (tick) url += `&tick=${tick}`;
  if (start) url += `&start=${start.toISOString()}`;
  if (end) url += `&end=${end.toISOString()}`;
  const res = await get(url);
  // console.log("res :", res);
  const result = res.body as MatchSeriesResult;
  result.items.map((i) => (i.date = new Date(i.date)));
  // console.log("countString :", countString);
  return result;
}
export async function createMatch(dto: CreateMatchDto) {
  const res = await post("/matches", dto);
  trackEvent("create_match", { board: dto.board });
  return res.body as Match;
}
export async function updateMatch(dto: UpdateMatchDto) {
  const res = await patch(`/matches/${dto._id}`, dto);
  trackEvent("update_match", { match: dto._id });
  return res.body as Match;
}
export async function cascadeMatch(id: string) {
  const res = await post(`/matches/${id}/cascade`, {});
  trackEvent("cascade_natch", { match: id });
  return res.body as Match;
}
function upload(path: string, file: Blob) {
  const formData = new FormData();
  formData.append("image", file);
  const options: any = {
    method: "POST",
    // headers: {},
    body: formData,
  };
  return request(path, options);
}
export interface ListEventsResult {
  records: Event[];
  count: number;
  offset: number;
}
export async function listEvents({
  offset = 0,
  search = "",
  params = {},
  limit = PAGE_SIZE,
  sort = "-createdAt",
  populate = [],
}: ListParams): Promise<ListEventsResult> {
  // console.log("listMembers :", offset, search);
  let url = `/tournaments?skip=${offset}&limit=${limit}&sort=${sort}`;
  if (search !== "") url += `&search=${search}`;
  if (populate?.length > 0) url += `&populate=${populate.join()}`;
  const queryString = JSON.stringify(params);
  if (queryString.length > 2) url += `&query=${queryString}`;
  const res = await get(url);
  // console.log("res :", res);
  const records = res.body as Event[];
  // console.log("records :", records);
  const countString = res.headers.get("x-total-count");
  // console.log("countString :", countString);
  const count = countString ? parseInt(countString) : 0;
  return { records, count, offset };
}
export async function createEvent(dto: CreateEventDto) {
  const res = await post("/tournaments", dto);
  trackEvent("create_event", { board: dto.board });
  return res.body as Event;
}
export async function updateEvent(dto: UpdateEventDto) {
  const id = dto._id;
  const res = await patch(`/tournaments/${id}`, dto);
  trackEvent("update_event", { member: dto._id });
  return res.body as Event;
}
export async function createEventRegistration(id: string) {
  const res = await post(`/tournaments/${id}/registrations`, {});
  trackEvent("register_for_event", { event: id });
  return res.body as Event;
}

export async function deleteEventRegistration({
  eventId,
  registrationId,
}: {
  eventId: string;
  registrationId: string;
}) {
  const res = await del(
    `/tournaments/${eventId}/registrations/${registrationId}`
  );
  trackEvent("unregister_for_event", { event: eventId });
  return res.body as Event;
}
export async function updateEventRegistration(dto: UpdateEventRegistrationDto) {
  const res = await patch(
    `/tournaments/${dto.eventId}/registrations/${dto.registrationId}`,
    dto
  );
  trackEvent("update_event_registration", {
    event: dto.eventId,
  });
  return res.body as Event;
}
export async function eventCheckin(eventId: string) {
  const res = await post(`/tournaments/${eventId}/checkin`, {});
  trackEvent("update_event_registration", {
    event: eventId,
  });
  return res.body as Event;
}
export async function payTournamentWithToken(eventId: string) {
  const res = await post(`/tournaments/${eventId}/tokenpayment`, {});
  trackEvent("token_event_payment", {
    event: eventId,
  });
  return res.body as Event;
}
export async function getEvent(shortLink: string) {
  // console.log("shortLink :", shortLink);
  const query = { shortLink };
  const res = await get(
    `/tournaments?query=${JSON.stringify(
      query
    )}&populate=matches,members,teams,tokenType`
  );
  const list = res.body as Event[];
  return list[0];
}
export function getEventScreenshotUrl(id: string) {
  return `${apiUrl}/tournaments/${id}/screenshot.webp`;
}
export async function addEventMembers(dto: AddEventMembersDto) {
  const id = dto.event;
  const res = await post(`/tournaments/${id}/members`, dto);
  trackEvent("add_event_members", { members: dto.members.length });
  return res.body as Event;
}
export async function addEventReferee(dto: AddTournamentRefereeDto) {
  const id = dto.event;
  const res = await post(`/tournaments/${id}/referees`, dto);
  trackEvent("add_event_referee");
  return res.body as Event;
}
export async function startEvent(id: string) {
  const res = await post(`/tournaments/${id}/start`, {});
  trackEvent("start_event", { event: id });
  return res.body as Event;
}
export async function completeEvent(id: string) {
  const res = await post(`/tournaments/${id}/complete`, {});
  trackEvent("manual_complete_event", { event: id });
  return res.body as Event;
}
export async function uploadEventImage(dto: UploadImageDto) {
  // console.log("uploadEventImage :", dto);
  const res = await upload(`/tournaments/${dto.id}/image`, dto.image);
  trackEvent("update_event_image", { event: dto.id });
  // console.log("res.body :", res.body);
  return res.body as ImageResponse;
}
export async function withdrawEventMember(dto: WithdrawMemberDto) {
  // console.log("uploadEventImage :", dto);
  const res = await post(`/tournaments/${dto.event}/withdrawal`, dto);
  trackEvent("withdraw_event_member", { event: dto.event });
  // console.log("res.body :", res.body);
  return res.body as Event;
}
export async function processScheduled() {
  const res = await get(`/tournaments/process-scheduled`);
  return res.body;
}
export async function listAchievementDefinitions() {
  const res = await get(`/achievements/definitions`);
  // console.log("res :", res);
  const records = res.body as AchievementDefinition[];
  // console.log("records :", records);
  return { records };
}
export interface ListAchievementsResult {
  records: Achievement[];
  count: number;
  offset: number;
}
export async function listAchievements({
  offset = 0,
  search = "",
  params = {},
  limit = PAGE_SIZE,
  sort = "-createdAt",
  populate = [],
}: ListParams): Promise<ListAchievementsResult> {
  // console.log("listMembers :", offset, search);
  let url = `/achievements?skip=${offset}&limit=${limit}&sort=${sort}`;
  if (search !== "") url += `&search=${search}`;
  if (populate?.length > 0) url += `&populate=${populate.join()}`;
  const queryString = JSON.stringify(params);
  if (queryString.length > 2) url += `&query=${queryString}`;
  const res = await get(url);
  // console.log("res :", res);
  const records = res.body as Achievement[];
  // console.log("records :", records);
  const countString = res.headers.get("x-total-count");
  // console.log("countString :", countString);
  const count = countString ? parseInt(countString) : 0;
  return { records, count, offset };
}
interface AchievementProgressItem {
  _id: string;
  complete: number;
  inProgress: number;
}
export async function getAchievementProgress(
  board?: string,
  user?: string
): Promise<AchievementProgressItem[]> {
  let url = `/achievements/progress?`;
  if (board) url += `&board=${board}`;
  if (user) url += `&user=${user}`;
  const res = await get(url);
  // console.log("res :", res);
  return res.body as AchievementProgressItem[];
}
interface TournamentStats {
  tournaments: number;
  firstPlaces: number;
  secondPlaces: number;
  thirdPlaces: number;
}
export async function getTournamentStats(
  board?: string,
  user?: string
): Promise<TournamentStats> {
  let url = `/tournaments/stats?`;
  if (board) url += `&board=${board}`;
  if (user) url += `&user=${user}`;
  // console.log("url :", url);
  const res = await get(url);
  // console.log("res :", res);
  return res.body as TournamentStats;
}
export interface Product {
  plan: PlanType;
  price: number;
  stripePrice: string;
}
export async function getProducts() {
  const res = await get(`/stripe/products`);
  return res.body as Product[];
}
export interface StripeIntent {
  clientSecret: string;
}
export async function getStripeIntent() {
  const res = await get(`/stripe/intent`);
  // console.log("res :", res);
  return res.body as StripeIntent;
}
export interface StripeCustomer {
  customerId: string;
}
export async function createCustomer() {
  const res = await post(`/stripe/customer`, {});
  // console.log("res :", res);
  return res.body as StripeCustomer;
}
export interface StripeSubscriptionRes {
  subscriptionId: string;
  clientSecret: string;
}
export interface StripeSubscriptionLinkRes {
  url: string;
}
export async function createSubscriptionLink(dto: CreateSubscriptionDto) {
  const res = await post(`/stripe/subscription-link`, dto);
  // console.log("res :", res);
  return res.body as StripeSubscriptionLinkRes;
}
export async function updateSubscription(dto: CreateSubscriptionDto) {
  const res = await patch(`/stripe/subscriptions`, dto);
  // console.log("res :", res);
  return res.body as StripeSubscriptionRes;
}
export interface StripeAccountRes {
  url: string;
}
export async function createStripeAccount({
  organisation,
  returnUrl,
}: {
  organisation: Organisation;
  returnUrl?: string;
}) {
  const res = await post(`/stripe/accounts`, {
    organisation: organisation._id,
    returnUrl,
  });
  // console.log("res :", res);
  return res.body as StripeAccountRes;
}
export async function createStripeDashboardLink() {
  const res = await post(`/stripe/accountdashboard`, {});
  // console.log("res :", res);
  return res.body as StripeAccountRes;
}
export async function createTournamentPaymentLink(event: Event) {
  const res = await post(`/tournaments/${event._id}/paymentlink`, {
    event: event._id,
  });
  // console.log("res :", res);
  return res.body as StripeAccountRes;
}
export async function downloadEventExport({
  id,
  format,
}: {
  id: string;
  format: string;
}) {
  await download(`/tournaments/${id}/export?format=${format}`);
  // console.log("res :", res);
}
export function del(path: string) {
  // console.log("path :", path);
  const options: any = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return request(path, options);
}
export function download(path: string) {
  // console.log("path :", path);
  const url = `${apiUrl}${path}`;
  const link = document.createElement("a");
  link.href = url;
  link.click();
}
export function post(path: string, body: any) {
  const options: any = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  return request(path, options);
}

export function patch(path: string, body: any) {
  const options: any = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  return request(path, options);
}
export function get(path: string) {
  // console.log("path :", path);
  return request(path);
}
async function request(path: string, options?: any) {
  // console.log("request :", path);
  if (!options) options = {};
  if (!options.headers) options.headers = {};
  const token = useAuthStore.getState().token;
  // console.log("token :", token);
  if (token) options.headers.Authorization = `Bearer ${token}`;
  const url = `${apiUrl}${path}`;
  // console.log("url :", url);
  const res = await fetch(url, options);
  // console.log("res :", res);
  const headers = res.headers;
  // console.log("headers :", headers);
  if (res.status === 401) {
    useAuthStore.getState().logout();
    window.location.href = "/login";
  } else if (res.status >= 400) {
    let title = "Error";
    let message = "Oops! Something went wrong";
    try {
      const body = await res.json();
      message = body.message;
      title = body.error;
    } catch (e) {}
    const error = new HttpError(res.status, res.statusText);
    // useAppStore.getState().setError(error);
    // dont tell sentry about expected exceptions
    // console.log("res.status :", res.status);
    // console.log("path :", path);
    let capture = true;
    let show = true;
    if (res.status === 404) {
      capture = false; // dont capture not founds
      show = false;
    }
    if (res.status < 500 && path.indexOf("auth") > -1) {
      // dont capture or show auth errors
      capture = false;
      show = false;
    }
    if (capture) Sentry.captureException(error);
    // console.log("show :", show);
    if (show) {
      showNotification({
        title: title || "Error",
        message,
        autoClose: true,
        color: "red",
      });
    }
    throw error;
  }
  const body = options.method === "DELETE" ? null : await res.json();
  // console.log("body :", body);
  return { body, headers };
}
