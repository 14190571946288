import { MantineProvider, createTheme } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import {
  SplitClient,
  SplitFactoryProvider,
} from "@splitsoftware/splitio-react";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Analytics } from "@vercel/analytics/react";
import { NextPage } from "next";
import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { ReactElement, ReactNode, useEffect } from "react";
import { identify, trackPage } from "../common/analytics";
import { getMe, getRefreshToken } from "../common/api";
import { useAppStore } from "../common/stores/app.store";
import { useAuthStore } from "../common/stores/auth.store";
import PageTitle from "../components/common/PageTitle";
import AppShell from "../components/layout/AppShell";
import MetaTags from "../components/layout/MetaTags";
import PushManager from "../components/notifications/PushManager";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import "@mantine/notifications/styles.css";
const networkMode =
  process.env.NODE_ENV === "development" ? "always" : "online";
const queryClient = new QueryClient({
  defaultOptions: { queries: { networkMode }, mutations: { networkMode } },
});

const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: process.env.NEXT_PUBLIC_SPLIT_API_KEY || "",
    // key represents your internal user id, or the account id that
    // the user belongs to.
    // This could also be a cookie you generate for anonymous users.
    key: "key",
  },
};

const NEXT_PUBLIC_GTM = process.env.NEXT_PUBLIC_GTM_ID || "";
export type NextPageWithLayout = NextPage & {
  // eslint-disable-next-line unused-imports/no-unused-vars
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};
const theme = createTheme({
  /** Put your mantine theme override here */
  // colorScheme: color,
  // fontSizes: {
  //   xs: "0.6rem",
  //   sm: "16px",
  //   md: "16px",
  //   lg: "1rem",
  //   xl: "1.2rem",
  // },
  // globalStyles: () => ({
  //   input: {
  //     fontSize: "16px !important",
  //   },
  // }),
  components: {
    Input: {
      styles: {
        input: {
          fontSize: "16px",
        },
      },
    },
    NumberInput: {
      styles: {
        input: {
          fontSize: "16px",
        },
      },
    },
    Select: {
      styles: {
        input: {
          fontSize: "16px",
        },
      },
    },
    MultiSelect: {
      styles: {
        input: {
          fontSize: "16px",
        },
        searchInput: {
          fontSize: "16px",
        },
      },
    },
    PasswordInput: {
      styles: {
        innerInput: {
          fontSize: "16px",
        },
      },
    },
  },
});
const UserWrapper = ({ children }: any) => {
  const token = useAuthStore((state) => state.token);
  const setToken = useAuthStore((state) => state.setToken);
  const logout = useAuthStore((state) => state.logout);
  const setIsPWA = useAppStore((s) => s.setIsPWA);
  const setIsPWAChecked = useAppStore((s) => s.setIsPWAChecked);
  const router = useRouter();
  // console.log('token :', token);
  const setUser = useAuthStore((state) => state.setUser);
  const user = useAuthStore((state) => state.user);
  useEffect(() => {
    const isPwa =
      window.matchMedia("(display-mode: standalone)").matches ||
      (window.navigator as any).standalone ||
      document.referrer.includes("android-app://");
    setIsPWA(isPwa);
    // setIsPWA(true);
    setIsPWAChecked(true);
  }, [setIsPWA, setIsPWAChecked]);
  useEffect(() => {
    trackPage();
    // page();
  }, [router.pathname]);
  useQuery(["me"], getMe, {
    enabled: token !== null,
    onSuccess: (data) => {
      // console.log("data :", data);
      setUser(data);
      if (user?._id !== data?._id) {
        identify(user);
      }
    },
    onError: () => {
      logout();
    },
  });
  useQuery(["refresh"], getRefreshToken, {
    enabled: token !== null,
    onSuccess: (token) => {
      // console.log("token :", token);
      setToken(token);
    },
    onError: () => {
      logout();
    },
  });
  return <SplitClient splitKey={user?._id}>{children}</SplitClient>;
};

const App = (props: AppPropsWithLayout) => {
  const { Component, pageProps } = props;
  // useEffect(() => {
  //   // trackPage();
  //   page();
  // }, [router.pathname]);

  const getLayout = Component.getLayout ?? ((page: ReactNode) => page);
  return (
    <>
      <Head>
        <MetaTags />
      </Head>
      <PageTitle title={"Scoreboarder. Fencing Tournaments: Sorted."} />
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA4_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GA4_ID}', {
            send_page_view: false
          });
        `}
      </Script>

      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
      >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${NEXT_PUBLIC_GTM}');`}</Script>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          // withGlobalStyles
          // withNormalizeCSS
          defaultColorScheme="auto"
          theme={theme}
        >
          <SplitFactoryProvider config={sdkConfig}>
            <Notifications style={{ marginBottom: 70 }} />
            <PushManager />
            <UserWrapper>
              <AppShell>{getLayout(<Component {...pageProps} />)}</AppShell>
            </UserWrapper>
          </SplitFactoryProvider>
        </MantineProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <Analytics />
    </>
  );
};

export default appWithTranslation(App);
