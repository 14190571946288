import { Anchor, Box, Container, Group } from "@mantine/core";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useEffect, useState } from "react";
import { isAndroid } from "react-device-detect";
import { useAppStore } from "../../common/stores/app.store";
import { useAuthStore } from "../../common/stores/auth.store";
import { PLAY_STORE_LINK } from "../../common/util";
import SchemeToggle from "../common/SchemeToggle";
import classes from "./Footer.module.css";
import Logo from "./Logo";

export default function FooterSimple() {
  const [hasToken, setHasToken] = useState(false);
  const token = useAuthStore((state) => state.token);
  useEffect(() => {
    setHasToken(token !== null);
  }, [token]);
  const isPwa = useAppStore((s) => s.isPwa);
  const { t } = useTranslation(["common"]);
  const [showAndroid, setShowAndroid] = useState(false);
  useEffect(() => {
    setShowAndroid(isAndroid);
  }, []);
  // const { classes } = useStyles();
  const links: { label: string; link: string; target?: string }[] = [
    { label: t("pricing"), link: "/pricing" },
    {
      label: t("support"),
      link: "/support",
    },
    { label: t("privacy"), link: "/privacy" },
    { label: t("terms"), link: "/terms" },
  ];
  if (!isPwa)
    links.push({
      label: t("app"),
      link: showAndroid ? PLAY_STORE_LINK : "/app",
      target: showAndroid ? "_blank" : "_self",
    });
  const items = links.map((link) => (
    <Anchor
      component={Link}
      c="dimmed"
      size="sm"
      target={link.target}
      key={link.label}
      href={link.link}
    >
      {link.label}
    </Anchor>
  ));
  const showItems = !hasToken;

  return (
    <Box className={classes.root}>
      {showItems && (
        <Container className={classes.inner}>
          <Group className={classes.group}>
            <Logo />
            <Group className={classes.links} justify="center">
              {items}
              <SchemeToggle />
            </Group>
          </Group>
        </Container>
      )}
    </Box>
  );
}
