import {
  Anchor,
  Avatar,
  Group,
  // MediaQuery,
  Text,
  useComputedColorScheme,
} from "@mantine/core";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useBoard } from "../../common/boards/board.hooks";
import { useAuthStore } from "../../common/stores/auth.store";
import { getInitials } from "../../common/util";
import classes from "./Logo.module.css";
const Logo = () => {
  // const { classes } = useStyles();
  const { t } = useTranslation();
  const token = useAuthStore((state) => state.token);
  const [root, setRoot] = useState("/");
  const router = useRouter();
  const { board } = useBoard();
  const isBoard = router.asPath.indexOf("/boards/") === 0;
  const computedColorScheme = useComputedColorScheme("light");
  const [src, setSrc] = useState("/assets/icons/ios/64_dark.png");
  useEffect(() => {
    setSrc(
      computedColorScheme === "dark"
        ? "/assets/icons/ios/64.png"
        : "/assets/icons/ios/64_dark.png"
    );
  }, [computedColorScheme]);
  // console.log("isBoard :", isBoard);
  useEffect(() => {
    setRoot(token ? "/dashboard" : "/");
  }, [token]);
  let image = src;
  if (isBoard && board?.image) image = board.image;
  return (
    <Anchor
      href={root}
      aria-label="home"
      component={Link}
      className={classes.root}
    >
      <Group gap={4} wrap="nowrap">
        <Avatar
          radius="sm"
          src={image}
          size={30}
          alt={isBoard && board ? board.name : t("scoreboarder")}
        >
          {isBoard && board?.name ? getInitials(board.name) : ""}
        </Avatar>
        {!isBoard && (
          <Text className={classes.text} visibleFrom="xs">
            {t("scoreboarder")}
          </Text>
        )}
      </Group>
    </Anchor>
  );
};
export default Logo;
